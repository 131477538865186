// import { yupResolver } from '@hookform/resolvers/yup';
import {
  Box,
  Button,
  Card,
  CardContent,
  CircularProgress,
  Container,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useForm } from 'react-hook-form';
import PhoneInput from 'react-phone-input-2';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { UserContext } from '../../../contexts/UserContext';
import { MY_GLOBAL_CONST } from '../../../utils/GlobalConstant';
import { aviatorGetData, aviatorPostData } from '../../../utils/api';
import { ToastError, ToastSuccess } from '../../../utils/helper';
// import { AdminUserSchema } from './ValidationSchema';

const OperatorForm = ({ action }) => {
  const params = useParams();

  const [loading, setLoading] = useState(false);

  const [status, setStatus] = useState('');
  const [live, setLive] = useState('');

  const [phoneNumber, setPhoneNumber] = useState('');
  const [countryCode, setCountryCode] = useState('');

  const [disable, setDisable] = useState(false);
  const Redirect = useNavigate();

  const { user } = useContext(UserContext);

  // const schema = []; // || AdminUserSchema(action);

  const {
    register,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = async (value) => {
    setLoading(true);
    setDisable(true);
    toast.dismiss();
    try {
      const data = {
        data: {
          status,
          isLive: live,
          api_base_url: value.api_base_url,
          web_url: value.web_url,
          languageCode: value.languageCode,
          currencyCode: value.currencyCode,
          ggrPercentage: value.ggrPercentage,
          minInputStake: value.minInputStake,
          maxInputStake: value.maxInputStake,
          maxWinAmount: value.maxWinAmount,
          housePot: value.housePot,
          jackpot: value.jackpot,
          houseBalancePer: value.houseBalancePer,
          jackpotBalancePer: value.jackpotBalancePer,
          returnToPlayerPot: value.returnToPlayerPot,
        },
      };

      if (params.id) {
        data.data.id = params.id;
      }

      const response = await aviatorPostData('/admin/admin-user/update', data);
      ToastSuccess(response.message);
    } catch (error) {
      console.log('error2 :>> ', error);
      ToastError(error.response.data.message);
    } finally {
      setLoading(false);
      setDisable(false);
      setTimeout(() => {
        toast.dismiss();
        Redirect('/aviator/operators');
      }, 800);
    }
  };
  const Status = [
    { id: 'active', label: 'Active' },
    { id: 'inactive', label: 'Inactive' },
  ];

  // const Live = [
  //   { id: true, label: 'Yes' },
  //   { id: false, label: 'No' },
  // ];

  const getData = async () => {
    try {
      setLoading(true);
      if (params.id) {
        // Fetch user data from the API based on the Id
        const response = await aviatorGetData(`/admin/admin-user/detail/${params.id}`);
        const data = response.data;
        // Set form values using setValue
        setValue('name', data.name);
        setValue('email', data.email);
        setValue('username', data.username);
        setValue('api_base_url', data.api_base_url);
        setValue('web_url', data.web_url);
        setValue('languageCode', data.languageCode);
        setValue('currencyCode', data.currencyCode);
        setValue('ggrPercentage', data.ggrPercentage);
        setValue('minInputStake', data.minInputStake);
        setValue('maxInputStake', data.maxInputStake);
        setValue('maxWinAmount', data.maxWinAmount);
        setValue('housePot', data.housePot);
        setValue('jackpot', data.jackpot);
        setValue('houseBalancePer', data.houseBalancePer);
        setValue('jackpotBalancePer', data.jackpotBalancePer);
        setValue('returnToPlayerPot', data.returnToPlayerPot);
        setStatus(data.status);
        setLive(data.is_live);
        setPhoneNumber(data.mobileNumber);
        setCountryCode(data.countryCode);
      }
    } catch (error) {
      console.log('error :>> ', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  const handleOnChange = (value, data) => {
    const country_code = data.dialCode;
    const phone_no = value.slice(data.dialCode.length);
    setValue('phone', phone_no, true);
    setValue('country_code', country_code);
  };

  return (
    <>
      <Helmet>
        <title>
          {' '}
          Operator {action} | {`${MY_GLOBAL_CONST.main_title}`} Admin{' '}
        </title>
      </Helmet>

      <Container maxWidth="lg">
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Operator {action}
          </Typography>
        </Stack>
        <Card>
          <CardContent>
            {loading ? (
              <Stack display="flex" flexDirection="row" justifyContent="center">
                <CircularProgress size={35} />
              </Stack>
            ) : (
              <form onSubmit={handleSubmit(onSubmit)}>
                <Stack direction="row">
                  <Box padding={2} width="50%">
                    <TextField
                      {...register('username')}
                      label="Username"
                      fullWidth
                      placeholder="Username"
                      InputLabelProps={{
                        shrink: true,
                        classes: { root: 'input-label' },
                      }}
                      InputProps={{
                        classes: { root: 'input-field' },
                        disabled: true,
                      }}
                    />
                    <Typography variant="body2" color="error">
                      {errors.username?.message}
                    </Typography>
                  </Box>

                  <Box padding={2} width="50%">
                    <TextField
                      {...register('name')}
                      label="Full Name"
                      placeholder="Full Name"
                      fullWidth
                      InputLabelProps={{
                        shrink: true,
                        classes: { root: 'input-label' },
                      }}
                      InputProps={{
                        classes: { root: 'input-field' },
                        disabled: true,
                      }}
                    />
                    <Typography variant="body2" color="error">
                      {errors.name?.message}
                    </Typography>
                  </Box>
                </Stack>
                <Stack direction="row">
                  <Box padding={2} width="50%">
                    <TextField
                      {...register('email')}
                      label="Email"
                      placeholder="Email"
                      fullWidth
                      InputLabelProps={{
                        shrink: true,
                        classes: { root: 'input-label' },
                      }}
                      InputProps={{
                        classes: { root: 'input-field' },
                        disabled: true,
                      }}
                    />
                    <Typography variant="body2" color="error">
                      {errors.email?.message}
                    </Typography>
                  </Box>

                  <Box sx={{}} padding={2} width="50%">
                    <PhoneInput
                      inputProps={{
                        name: 'phone',
                        required: true,
                        autoFocus: true,
                        ref: { ...register('phone') },
                      }}
                      variant="outlined"
                      value={`${countryCode}${phoneNumber}`}
                      placeholder="Phone No"
                      country={`${MY_GLOBAL_CONST.country_short_code}`}
                      disabled
                      InputLabelProps={{
                        height: '56px',
                        shrink: true,
                        classes: { root: 'input-label' },
                      }}
                      InputProps={{ classes: { root: 'input-field' } }}
                    />
                    <Typography variant="body2" color="error">
                      {errors.phone?.message}
                    </Typography>
                  </Box>
                </Stack>
                <Stack direction="row">
                  <Box padding={2} width="50%">
                    <TextField
                      {...register('api_base_url')}
                      label="Api Base Url"
                      fullWidth
                      placeholder="Api Base Url"
                      InputLabelProps={{
                        shrink: true,
                        classes: { root: 'input-label' },
                      }}
                      InputProps={{ classes: { root: 'input-field' } }}
                    />
                    <Typography variant="body2" color="error">
                      {errors.api_base_url?.message}
                    </Typography>
                  </Box>

                  <Box padding={2} width="50%">
                    <TextField
                      {...register('web_url')}
                      label="Web Url"
                      fullWidth
                      placeholder="Web Url"
                      InputLabelProps={{
                        shrink: true,
                        classes: { root: 'input-label' },
                      }}
                      InputProps={{ classes: { root: 'input-field' } }}
                    />
                    <Typography variant="body2" color="error">
                      {errors.web_url?.message}
                    </Typography>
                  </Box>
                </Stack>
                <Stack direction="row">
                  <Box display="flex" padding={2} width="50%">
                    <FormControl fullWidth sx={{ textAlign: 'left' }}>
                      <InputLabel id="status-label">{!params.id ? 'Status' : ''}</InputLabel>
                      <Select
                        labelId="status-label"
                        id="status"
                        name="status"
                        {...register('status')}
                        value={status}
                        onChange={(event) => setStatus(event.target.value)}
                      >
                        <MenuItem key={0} value={null}>
                          Select Status
                        </MenuItem>

                        {Status.map((item, index) => (
                          <MenuItem key={index} value={item.id}>
                            {item.label}
                          </MenuItem>
                        ))}
                      </Select>
                      <Typography variant="body2" color="error">
                        {errors.role_id?.message}
                      </Typography>
                    </FormControl>
                  </Box>
                  <Box display="flex" alignItems="center" padding={2} width="50%">
                    <Typography align="left" sx={{ marginRight: 2, width: '20%' }}>
                      Is Live
                    </Typography>
                    <RadioGroup
                      row
                      value={live?.toString() || ''}
                      onChange={(event) => setLive(event.target.value === 'true')}
                    >
                      <FormControlLabel
                        value="true"
                        control={<Radio sx={{ color: 'primary.main' }} />}
                        label={
                          <Typography variant="button" sx={{ color: 'text.primary' }}>
                            Yes
                          </Typography>
                        }
                        sx={{ marginRight: 2 }}
                      />
                      <FormControlLabel
                        value="false"
                        control={<Radio sx={{ color: 'primary.main' }} />}
                        label={
                          <Typography variant="button" sx={{ color: 'text.primary' }}>
                            No
                          </Typography>
                        }
                        sx={{ marginRight: 2 }}
                      />
                    </RadioGroup>
                  </Box>
                </Stack>
                <Stack direction="row">
                  <Box padding={2} width="50%">
                    <TextField
                      {...register('languageCode')}
                      label="Language Code"
                      fullWidth
                      placeholder="Language Code"
                      InputLabelProps={{
                        shrink: true,
                        classes: { root: 'input-label' },
                      }}
                      InputProps={{ classes: { root: 'input-field' } }}
                    />
                    <Typography variant="body2" color="error">
                      {errors.languageCode?.message}
                    </Typography>
                  </Box>

                  <Box padding={2} width="50%">
                    <TextField
                      {...register('currencyCode')}
                      label="Currency Code"
                      fullWidth
                      placeholder="Currency Code"
                      InputLabelProps={{
                        shrink: true,
                        classes: { root: 'input-label' },
                      }}
                      InputProps={{ classes: { root: 'input-field' } }}
                    />
                    <Typography variant="body2" color="error">
                      {errors.currencyCode?.message}
                    </Typography>
                  </Box>

                  <Box padding={2} width="50%">
                    <TextField
                      {...register('ggrPercentage', { valueAsNumber: true, defaultValue: 0 })}
                      label="GGR (%)"
                      fullWidth
                      placeholder="GGR (%)"
                      InputLabelProps={{
                        shrink: true,
                        classes: { root: 'input-label' },
                      }}
                      InputProps={{
                        classes: { root: 'input-field' },
                        type: 'number',
                        inputProps: { step: 0.01, min: 0.0, max: 100 },
                        defaultValue: 0,
                        disabled: false,
                      }}
                    />
                    <Typography variant="body2" color="error">
                      {errors.ggrPercentage?.message}
                    </Typography>
                  </Box>
                </Stack>
                <Stack direction="row">
                  <Box padding={2} width="50%">
                    <TextField
                      {...register('minInputStake')}
                      label="Min Input Stack"
                      fullWidth
                      placeholder="Min Input Stack"
                      InputLabelProps={{
                        shrink: true,
                        classes: { root: 'input-label' },
                      }}
                      InputProps={{
                        classes: { root: 'input-field' },
                        type: 'number',
                        inputProps: { step: 1, min: 1, max: 1000 },
                        defaultValue: 1,
                        disabled: false,
                      }}
                    />
                    <Typography variant="body2" color="error">
                      {errors.minInputStake?.message}
                    </Typography>
                  </Box>

                  <Box padding={2} width="50%">
                    <TextField
                      {...register('maxInputStake')}
                      label="Max Input Stake"
                      fullWidth
                      placeholder="Max Input Stake"
                      InputLabelProps={{
                        shrink: true,
                        classes: { root: 'input-label' },
                      }}
                      InputProps={{
                        classes: { root: 'input-field' },
                        type: 'number',
                        inputProps: { step: 1, min: 1, max: 100000 },
                        defaultValue: 100,
                        disabled: false,
                      }}
                    />
                    <Typography variant="body2" color="error">
                      {errors.maxInputStake?.message}
                    </Typography>
                  </Box>

                  <Box padding={2} width="50%">
                    <TextField
                      {...register('maxWinAmount', { valueAsNumber: true, defaultValue: 0 })}
                      label="Max Win Amount"
                      fullWidth
                      placeholder="Max Win Amount"
                      InputLabelProps={{
                        shrink: true,
                        classes: { root: 'input-label' },
                      }}
                      InputProps={{
                        classes: { root: 'input-field' },
                        type: 'number',
                        inputProps: { step: 1, min: 1, max: 1000000 },
                        defaultValue: 10000,
                        disabled: false,
                      }}
                    />
                    <Typography variant="body2" color="error">
                      {errors.maxWinAmount?.message}
                    </Typography>
                  </Box>
                </Stack>
                <Stack direction="row">
                  <Box padding={2} width="50%">
                    <TextField
                      {...register('housePot', { valueAsNumber: true, defaultValue: 0 })}
                      label="House Pot"
                      fullWidth
                      placeholder="House Pot"
                      InputLabelProps={{
                        shrink: true,
                        classes: { root: 'input-label' },
                      }}
                      InputProps={{
                        classes: { root: 'input-field' },
                        type: 'number',
                        inputProps: { step: 0.01 },
                        defaultValue: 0,
                        disabled: true,
                      }}
                    />
                    <Typography variant="body2" color="error">
                      {errors.housePot?.message}
                    </Typography>
                  </Box>
                  <Box padding={2} width="50%">
                    <TextField
                      {...register('jackpot', { valueAsNumber: true, defaultValue: 0 })}
                      label="Jackpot"
                      fullWidth
                      placeholder="Jackpot"
                      InputLabelProps={{
                        shrink: true,
                        classes: { root: 'input-label' },
                      }}
                      InputProps={{
                        classes: { root: 'input-field' },
                        type: 'number',
                        inputProps: { step: 0.01 },
                        defaultValue: 0,
                        disabled: true,
                      }}
                    />
                    <Typography variant="body2" color="error">
                      {errors.jackpot?.message}
                    </Typography>
                  </Box>

                  <Box padding={2} width="50%">
                    <TextField
                      {...register('houseBalancePer', { valueAsNumber: true, defaultValue: 0 })}
                      label="House Balance Per"
                      fullWidth
                      placeholder="House Balance Per"
                      InputLabelProps={{
                        shrink: true,
                        classes: { root: 'input-label' },
                      }}
                      InputProps={{
                        classes: { root: 'input-field' },
                        type: 'number',
                        inputProps: { step: 0.01 },
                        defaultValue: 0,
                        disabled: true,
                      }}
                    />
                    <Typography variant="body2" color="error">
                      {errors.houseBalancePer?.message}
                    </Typography>
                  </Box>
                </Stack>
                <Stack direction="row">
                  <Box padding={2} width="50%">
                    <TextField
                      {...register('jackpotBalancePer', { valueAsNumber: true, defaultValue: 0 })}
                      label="Jackpot Balance Per"
                      fullWidth
                      placeholder="Jackpot Balance Per"
                      InputLabelProps={{
                        shrink: true,
                        classes: { root: 'input-label' },
                      }}
                      InputProps={{
                        classes: { root: 'input-field' },
                        type: 'number',
                        inputProps: { step: 0.01 },
                        defaultValue: 0,
                        disabled: true,
                      }}
                    />
                    <Typography variant="body2" color="error">
                      {errors.jackpotBalancePer?.message}
                    </Typography>
                  </Box>

                  <Box padding={2} width="50%">
                    <TextField
                      {...register('returnToPlayerPot', { valueAsNumber: true, defaultValue: 0 })}
                      label="Return To Player Pot"
                      fullWidth
                      placeholder="Return To Player Pot"
                      InputLabelProps={{
                        shrink: true,
                        classes: { root: 'input-label' },
                      }}
                      InputProps={{
                        classes: { root: 'input-field' },
                        type: 'number',
                        inputProps: { step: 0.01 },
                        defaultValue: 0,
                        disabled: true,
                      }}
                    />
                    <Typography variant="body2" color="error">
                      {errors.returnToPlayerPot?.message}
                    </Typography>
                  </Box>
                </Stack>
                <Box display="flex" padding={2} width="50%">
                  <Button type="submit" variant="contained" size="large" disabled={disable}>
                    {action === 'Edit' ? 'Update' : action} Operator
                  </Button>
                </Box>
              </form>
            )}
          </CardContent>
        </Card>
      </Container>
    </>
  );
};

export default OperatorForm;
