import { Icon } from '@iconify/react';
import ArrowDownwardOutlinedIcon from '@mui/icons-material/ArrowDownwardOutlined';
import ArrowUpwardOutlinedIcon from '@mui/icons-material/ArrowUpwardOutlined';
import {
  Autocomplete,
  Box,
  Button,
  Card,
  CardContent,
  CircularProgress,
  Container,
  FormControl,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Typography,
} from '@mui/material';
import dayjs from 'dayjs';
import IconButton from '@mui/material/IconButton';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { DesktopDateRangePicker } from '@mui/x-date-pickers-pro';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DemoContainer, DemoItem } from '@mui/x-date-pickers/internals/demo';
import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useForm } from 'react-hook-form';
import { aviatorGetData, aviatorPostData } from '../../utils/api';
import { MY_GLOBAL_CONST } from '../../utils/GlobalConstant';
import { formatDate, formatNumber, getNestedValue, showCurrency } from '../../utils/helper';
import { fNumber } from '../../utils/formatNumber';
import config from '../../config';
import Iconify from '../../components/iconify';

const Player = ({ userType }) => {
  const columns = [
    {
      id: 0,
      name: 'Operator',
      align: 'center',
      columnName: 'operator.username',
      isOrder: false,
      isRowSkip: userType !== 'aviator',
    },
    {
      id: 1,
      name: 'Player ID',
      align: 'center',
      columnName: 'external_user_id',
      isOrder: false,
    },
    {
      id: 2,
      name: 'Username',
      align: 'center',
      columnName: 'username',
      isOrder: false,
    },
    {
      id: 3,
      name: 'Email',
      align: 'center',
      columnName: 'email',
      isOrder: false,
    },
    {
      id: 4,
      name: 'Language Code',
      align: 'center',
      columnName: 'languageCode',
      isOrder: false,
    },
    {
      id: 5,
      name: 'No of bets',
      align: 'center',
      columnName: 'totalBetCount',
      isOrder: false,
      isCount: true,
    },
    {
      id: 6,
      name: 'Bet Amount',
      align: 'center',
      columnName: 'totalBetAmount',
      isOrder: false,
      isAmount: true,
    },
    {
      id: 7,
      name: 'No of Winnings',
      align: 'center',
      columnName: 'totalWinBetCount',
      isOrder: false,
      isCount: true,
    },
    {
      id: 8,
      name: 'Winning Amount',
      align: 'center',
      columnName: 'totalWinBetAmount',
      isOrder: false,
      isAmount: true,
    },
    {
      id: 9,
      name: 'No of Lost',
      align: 'center',
      columnName: 'totalLostBetCount',
      isOrder: false,
      isCount: true,
    },
    {
      id: 10,
      name: 'Lost Amount',
      align: 'center',
      columnName: 'totalLostBetAmount',
      isOrder: false,
      isAmount: true,
    },
    {
      id: 11,
      name: 'No of Cancel',
      align: 'center',
      columnName: 'totalCancelBetCount',
      isOrder: false,
      isCount: true,
    },
    {
      id: 12,
      name: 'Cancel Amount',
      align: 'center',
      columnName: 'totalCancelBetAmount',
      isOrder: false,
      isAmount: true,
    },
    {
      id: 13,
      name: 'Balance',
      align: 'center',
      columnName: 'balance',
      isOrder: false,
      isAmount: true,
    },
    {
      id: 14,
      name: 'Net Bet Amount',
      align: 'center',
      columnName: 'totalNetBetAmount',
      isOrder: false,
      isAmount: true,
    },
    {
      id: 15,
      name: 'RTP %',
      align: 'center',
      columnName: 'totalRTP',
      isOrder: false,
      isPercentage: true,
    },
    {
      id: 16,
      name: 'Created At',
      align: 'center',
      columnName: 'createdAt',
      isOrder: false,
      isDate: true,
    },
  ];

  const REACT_APP_AVIATOR_API_URL = config.apiAviatorUrl.slice(0, -6);

  const currentDate = new Date();
  const Today_Date = dayjs(currentDate);
  const [dateDay, setdateDay] = useState('custom');
  const [selectedDateRange, setSelectedDateRange] = useState(['', '']);
  const [UserRTPList, setUserRTPList] = useState([]);
  const [sortData, setSortData] = useState('ASC');
  const [sortBy, setSortBY] = useState('');
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);
  const [loading, SetLoading] = useState(false);
  const [extra_data, setExtraData] = useState([]);
  const [filterName, setFilterName] = useState(null);
  const [operator, setOperator] = useState([]);
  const [selectOperator, setSelectOperator] = useState(null);

  const { handleSubmit, register, setValue } = useForm();

  const fetchUsersRTP = async ({
    sortBy = '',
    sortData = '',
    start_Date = '',
    end_Date = '',
    page = 1,
    rowsPerPage = 10,
    filterName = '',
    operatorId = '',
  }) => {
    SetLoading(true);
    const start_date = new Date(start_Date === '' ? currentDate : start_Date);
    const endDate = new Date(end_Date === '' ? Today_Date : end_Date);
    const utcStartDate = new Date(Date.UTC(start_date.getFullYear(), start_date.getMonth(), start_date.getDate()));
    const utcEndDate = new Date(Date.UTC(endDate.getFullYear(), endDate.getMonth(), endDate.getDate()));

    try {
      const data = {
        data: {
          page: page + 1,
          limit: rowsPerPage,
          search: filterName === null ? '' : filterName,
          order_key: sortBy !== '' ? sortBy : '',
          order_value: sortData !== '' ? sortData : '',
          startDate: start_Date === '' ? '' : formatDate(utcStartDate, 'utcToClientStartDateTime'),
          endDate: end_Date === '' ? '' : formatDate(utcEndDate, 'utcToClientEndDateTime'),
        },
      };

      if (userType === 'aviator') {
        data.data.operatorId = operatorId === '' ? '' : operatorId;
      }

      const response = await aviatorPostData(`/user/player`, data);
      setUserRTPList(response.data.list);
      setExtraData(response.data.pagination);
      SetLoading(false);
    } catch (error) {
      console.log('error :>> ', error);
      SetLoading(false);
    }
  };

  const getOperators = async () => {
    const data = {
      data: {
        search: filterName === null ? '' : filterName,
      },
    };
    const response = await aviatorGetData(`/admin/admin-user`, data);
    const operatorData = response.data;
    setOperator(operatorData);
  };

  const saveBlob = (blob, filename) => {
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = filename;
    a.click();
    window.URL.revokeObjectURL(url);
  };

  const downloadExcel = async () => {
    SetLoading(true);
    const startDate = new Date(selectedDateRange[0] === '' ? currentDate : selectedDateRange[0]);
    const endDate = new Date(selectedDateRange[1] === '' ? Today_Date : selectedDateRange[1]);

    const utcStartDate = new Date(Date.UTC(startDate.getFullYear(), startDate.getMonth(), startDate.getDate()));
    const utcEndDate = new Date(Date.UTC(endDate.getFullYear(), endDate.getMonth(), endDate.getDate()));

    try {
      const data = {
        data: {
          startDate: selectedDateRange[0] === '' ? '' : formatDate(utcStartDate, 'utcToClientStartDateTime'),
          endDate: selectedDateRange[1] === '' ? '' : formatDate(utcEndDate, 'utcToClientEndDateTime'),
          search: filterName === null ? '' : filterName,
          isDownloadFile: true,
        },
      };

      if (userType === 'aviator') {
        data.data.operatorId = selectOperator === null ? '' : selectOperator._id;
      }

      const response = await aviatorPostData('/user/player', data);
      const blob = await fetch(`${REACT_APP_AVIATOR_API_URL}${response.filePath}`).then((response) => response.blob());
      const date = currentDate.toISOString().split('T')[0];
      const time = currentDate.toTimeString().split(' ')[0];
      const filename = `Player_RTP - ${date} at ${time}.xlsx`;
      saveBlob(blob, filename);
    } catch (error) {
      console.error('Error downloading Excel file:', error);
    } finally {
      SetLoading(false);
    }
  };

  const handleSort = (sortBy) => {
    setSortBY(sortBy);
    setSortData(sortData === 'ASC' ? 'DESC' : 'ASC');
    fetchUsersRTP({
      sortBy,
      sortData: sortData === 'ASC' ? 'DESC' : 'ASC',
      start_Date: selectedDateRange[0],
      end_Date: selectedDateRange[1],
      page,
      rowsPerPage,
      filterName,
    });
  };

  useEffect(() => {
    fetchUsersRTP({
      sortBy,
      start_Date: selectedDateRange[0],
      end_Date: selectedDateRange[1],
      page,
      rowsPerPage,
      filterName,
      operatorId: selectOperator?._id,
    });
    getOperators();
    setSelectedDateRange(['', '']);
  }, []);

  const yesterday = new Date(currentDate);
  yesterday.setDate(currentDate.getDate() - 1);

  const lastSevenDays = new Date(currentDate);
  lastSevenDays.setDate(currentDate.getDate() - 7);

  const lastMonth = new Date(currentDate);
  lastMonth.setMonth(currentDate.getMonth() - 1);

  const onchange = (event) => {
    setdateDay(event.target.value);
    switch (event.target.value) {
      case 'Today':
        return setSelectedDateRange([dayjs(currentDate), Today_Date]);
      case 'Yesterday':
        return setSelectedDateRange([dayjs(yesterday), dayjs(yesterday)]);
      case 'Last 7 days':
        return setSelectedDateRange([dayjs(lastSevenDays), Today_Date]);
      case 'Last 30 days':
        return setSelectedDateRange([dayjs(lastMonth), Today_Date]);
      case 'custom':
        return setSelectedDateRange(['', '']);
      default:
        return setSelectedDateRange([Today_Date, Today_Date]);
    }
  };

  const onchangeOperator = (event, newValue) => {
    setValue('operatorId', newValue?._id);
    setSelectOperator(newValue);
  };

  const handleDateChange = (newValue) => {
    setSelectedDateRange([newValue[0] !== null ? newValue[0] : '', newValue[1] !== null ? newValue[1] : '']);
    if (newValue[0] && newValue[1]) {
      setSelectedDateRange(newValue);
    }
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    fetchUsersRTP({
      sortBy,
      sortData: sortBy ? sortData : '',
      start_Date: selectedDateRange[0],
      end_Date: selectedDateRange[1],
      page: newPage,
      rowsPerPage,
      filterName,
      operatorId: selectOperator?._id,
    });
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(event.target.value);
    fetchUsersRTP({
      sortBy,
      sortData: sortBy ? sortData : '',
      start_Date: selectedDateRange[0],
      end_Date: selectedDateRange[1],
      page,
      rowsPerPage: event.target.value,
      filterName,
      operatorId: selectOperator?._id,
    });
  };

  const onSearch = () => {
    setPage(0);
    fetchUsersRTP({
      sortBy,
      sortData: sortBy ? sortData : '',
      start_Date: selectedDateRange[0],
      end_Date: selectedDateRange[1],
      page: 0,
      rowsPerPage,
      filterName,
      operatorId: selectOperator?._id,
    });
  };

  const clearFilter = () => {
    setSelectedDateRange(['', '']);
    setSortBY('');
    setPage(0);
    setFilterName('');
    setValue(('operatorId', ''));
    setSelectOperator(null);
    setdateDay('custom');
    fetchUsersRTP({ page: 0, rowsPerPage, filterName: null });
  };

  const handleFilterByName = (event) => {
    setPage(0);
    setFilterName(event.target.value);
  };

  return (
    <>
      <Helmet>
        <title> Player RTP | {`${MY_GLOBAL_CONST.main_title}`} Admin </title>
      </Helmet>
      <Container maxWidth="xl" className="cardDesign">
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Box display="flex">
            <Icon icon="uil:user-square" style={{ fontSize: '23px', marginRight: '3px', marginTop: '6px' }} />
            <Typography variant="h4" gutterBottom>
              Player RTP
            </Typography>
          </Box>
        </Stack>

        <form onSubmit={handleSubmit(onSearch)}>
          <Card
            sx={{
              minWidth: 275,
              height: 'auto',
              marginTop: '45px',
            }}
          >
            <CardContent>
              <Stack display="flex" flexDirection="row" className="temptranstaionStack pd0_mrg0">
                <Box mt={3} sx={{ flexBasis: '25%' }} className="slectInput width_100 pd0_mrg0">
                  <FormControl sx={{ minWidth: 150 }} fullWidth>
                    <InputLabel id="demo-simple-select-label">Select Dates</InputLabel>
                    <Select name="dates" id="dates" value={dateDay} onChange={onchange}>
                      <MenuItem value={'Today'}>Today</MenuItem>
                      <MenuItem value={'Yesterday'}>Yesterday</MenuItem>
                      <MenuItem value={'Last 7 days'}>Last 7 days</MenuItem>
                      <MenuItem value={'Last 30 days'}>Last 30 days</MenuItem>
                      <MenuItem value={'custom'}>Custom</MenuItem>
                    </Select>
                  </FormControl>
                </Box>

                <Box padding={2} sx={{ flexBasis: '50%' }} className="slectInput width_100 pd0_mrg0">
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DemoContainer components={['DesktopDateRangePicker']}>
                      <DemoItem component="DesktopDateRangePicker">
                        <DesktopDateRangePicker
                          localeText={{ start: 'Start Date', end: 'End Date' }}
                          size="small"
                          maxDate={Today_Date}
                          value={selectedDateRange}
                          onChange={handleDateChange}
                          disabled={dateDay !== 'custom'}
                          calendars={1}
                          format="DD-MM-YYYY"
                          slotProps={{
                            textField: {
                              error: false,
                            },
                          }}
                        />
                      </DemoItem>
                    </DemoContainer>
                  </LocalizationProvider>
                </Box>

                {userType === 'aviator' && (
                  <Box padding={3} sx={{ flexBasis: '40%' }} className="slectInput width_100 pd0_mrg0">
                    <FormControl sx={{ minWidth: 150 }} fullWidth>
                      <Autocomplete
                        fullWidth
                        name="selectOperator"
                        disablePortal
                        id="_id"
                        {...register('_id')}
                        options={operator}
                        autoHighlight
                        value={selectOperator}
                        onChange={onchangeOperator}
                        getOptionLabel={(option) => `${option.username}`}
                        renderInput={(params) => (
                          <TextField {...params} value={selectOperator} selectPlayer label=" Select Operators" />
                        )}
                      />
                    </FormControl>
                  </Box>
                )}

                <Box padding={3} sx={{ flexBasis: '40%' }} className="slectInput width_100 pd0_mrg0">
                  <TextField value={filterName} type="text" onChange={handleFilterByName} placeholder="Search..." />
                </Box>

                <Box margin={3} className="slectInput width_100 pd0_mrg0">
                  <Button
                    onClick={clearFilter}
                    color="secondary"
                    variant="outlined"
                    size="large"
                    disableElevation
                    disabled={loading}
                  >
                    Clear
                  </Button>
                </Box>
                <Box margin={3} className="slectInput width_100 pd0_mrg0">
                  <Button
                    type="submit"
                    color="secondary"
                    variant="contained"
                    size="large"
                    disableElevation
                    disabled={loading}
                  >
                    Search
                  </Button>
                </Box>
              </Stack>
              <Stack
                display="flex"
                sx={{ justifyContent: 'end' }}
                flexDirection="row"
                className="temptranstaionStack pd0_mrg0"
              >
                <Box
                  display="flex"
                  sx={{ justifyContent: 'end', flexBasis: '20%' }}
                  mr={3}
                  className="slectInput width_100 pd0_mrg0"
                >
                  <Button
                    display="flex"
                    sx={{ justifyContent: 'end' }}
                    onClick={downloadExcel}
                    color="secondary"
                    variant="contained"
                    size="large"
                    disabled={loading}
                    disableElevation
                  >
                    <Iconify icon={'eva:download-outline'} />
                    {/* Download Excel */}
                  </Button>
                </Box>
              </Stack>

              <Box mt={3} sx={{ overflow: 'auto' }}>
                <TableContainer component={Paper} sx={{ height: 540, scrollbarWidth: 'thin' }}>
                  <Table sx={{ minWidth: '2000px' }} stickyHeader aria-label="simple table" margin={1}>
                    <TableHead>
                      <TableRow>
                        {columns.map((column) => (
                          <>
                            {!column.isRowSkip && (
                              <TableCell
                                align={column.align}
                                sx={{ width: '250px', padding: '10px' }}
                                onClick={() => (column.isOrder ? handleSort(column.columnName) : '')}
                              >
                                {column.name}
                                {column.isOrder ? (
                                  <IconButton aria-label="toggle password visibility">
                                    {sortData === 'ASC' ? (
                                      <ArrowUpwardOutlinedIcon sx={{ fontSize: '16px !important' }} />
                                    ) : (
                                      <ArrowDownwardOutlinedIcon sx={{ fontSize: '16px !important' }} />
                                    )}
                                  </IconButton>
                                ) : (
                                  ''
                                )}
                              </TableCell>
                            )}
                          </>
                        ))}
                      </TableRow>
                    </TableHead>

                    {!loading ? (
                      <TableBody>
                        {UserRTPList?.map((item) => (
                          <>
                            {' '}
                            <TableRow key={item._id} padding={0}>
                              {columns.map((column) => (
                                <>
                                  {!column.isRowSkip && (
                                    <TableCell align="center" sx={{ width: '250px' }}>
                                      {column.isDate
                                        ? formatDate(item?.[column.columnName], 'ticket datetime')
                                        : column.isCount
                                          ? fNumber(item?.[column.columnName])
                                          : column.isAmount
                                            ? `${fNumber(item?.[column.columnName])} ${showCurrency()}`
                                            : column.isPercentage
                                              ? `${formatNumber(item?.[column.columnName])} %`
                                              : getNestedValue(item, column.columnName) || '-'}
                                    </TableCell>
                                  )}
                                </>
                              ))}
                            </TableRow>
                          </>
                        ))}
                      </TableBody>
                    ) : (
                      <TableCell align="center" colSpan={8}>
                        <CircularProgress />
                      </TableCell>
                    )}
                    {!loading && UserRTPList.length <= 0 && (
                      <TableCell align="center" colSpan={8}>
                        <Typography variant="h6" textTransform={'capitalize'}>
                          {' '}
                          data not found !
                        </Typography>
                      </TableCell>
                    )}
                  </Table>
                  {!loading && UserRTPList.length > 0 && (
                    <TableFooter sx={{}}>
                      <TableRow>
                        <TableCell
                          colSpan={3}
                          align="left"
                          sx={{ fontWeight: 'bold', fontSize: '16px', color: '#000' }}
                        >
                          Total Entries : {extra_data ? extra_data.totalCounts : 0}
                        </TableCell>
                      </TableRow>
                    </TableFooter>
                  )}

                  {/* </>
                  )} */}
                </TableContainer>
                {!loading && UserRTPList.length > 0 && (
                  <TablePagination
                    rowsPerPageOptions={[10, 25, 50]}
                    component="div"
                    count={extra_data ? extra_data?.totalCounts : 0}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                  />
                )}
              </Box>
            </CardContent>
          </Card>
        </form>
      </Container>
    </>
  );
};

export default Player;
