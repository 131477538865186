import crypto from 'crypto-js';
import moment from 'moment';
import numeral from 'numeral';
import { toast } from 'react-toastify';
import CreditTransactions from '../admin/Agent/CreditTransactions';
import MyCashiers from '../admin/Agent/MyCashiers';
import PlayerRTP from '../admin/Agent/PlayerRTP';
import ShopPage from '../admin/shop';
import BalanceControlCenter from '../cashier/BalanceControlCenter';
import BalanceTransfer from '../cashier/BalanceTransfer';
import BetSlipCheck from '../cashier/BetSlipCheck';
import BetTicketCode from '../cashier/BetTicketCode';
import BettingReport from '../cashier/BettingReport';
import CreatePlayer from '../cashier/CreatePlayer';
import CreditRequest from '../cashier/CreditRequest';
import Player from '../admin/Aviator/Player';
import PlayerBet from '../admin/Aviator/PlayerBet';
import FundTransferRequest from '../cashier/FundTransferRequest';
import Payout from '../cashier/Payout';
import PlaceBetTicketCode from '../cashier/PlaceBetTicketCode';
import PrintSummaryReport from '../cashier/PrintSummaryReport';
import Profile from '../cashier/Profile';
import ShopBalanceTransfer from '../cashier/ShopBalanceTransfer';
import Transaction from '../cashier/Transaction';
import config from '../config';
import AgentDashboardAppPage from '../pages/AgentDashboardAppPage';
import CashierDashboardAppPage from '../pages/CashierDashboardAppPage';
import OperatorDashboardAppPage from '../pages/OperatorDashboardAppPage';
import { MY_GLOBAL_CONST } from './GlobalConstant';
import AviatorAdminDashboardAppPage from '../pages/AviatorAdminDashboardAppPage';
import OperatorRTP from '../admin/Aviator/OperatorRTP';
import Operator from '../admin/Aviator/operator';
import OperatorForm from '../admin/Aviator/operator/form';

export const formatDate = (value, from) => {
  const date = new Date(value);

  // const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
  // const days = ['Sun', 'Mon', 'Tues', 'Wed', 'Thu', 'Fri', 'Sat'];
  // const day = days[date.getDay()];
  // const hr = date.getHours();
  let min = date.getMinutes();
  // const sec = date.getSeconds();
  if (min < 10) {
    min = `0${min}`;
  }

  const datenum = date.getDate();
  // const month = months[date.getMonth()];
  const monthnum = Number(date.getMonth()) + 1;
  const year = date.getFullYear();

  if (from === 'datepicker')
    return `${year}-${monthnum < 10 ? `0${monthnum}` : monthnum}-${datenum < 10 ? `0${datenum}` : datenum}`;
  if (from === 'clockdate') return `${date.toLocaleDateString(`en-ET`, { timeZone: date.getTimeZone })}`;
  if (from === 'clocktime')
    return `${date.toLocaleTimeString(`en-ET`, { timeStyle: 'long', timeZone: date.getTimeZone })}`;
  if (from === 'ticketpreview')
    return `${date.toLocaleString(`en-ET`, { dateStyle: 'medium', timeStyle: 'short', timeZone: date.getTimeZone })}`;
  if (from === 'fixturedate')
    return `${date.toLocaleDateString(`en-ET`, { day: '2-digit', month: '2-digit', timeZone: date.getTimeZone })}`;
  if (from === 'fixturedetaildate')
    return `${date.toLocaleDateString(`en-ET`, {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
      timeZone: date.getTimeZone,
    })}`;
  if (from === 'fixturetime')
    return `${date.toLocaleTimeString(`en-ET`, { timeStyle: 'short', timeZone: date.getTimeZone })}`;
  if (from === 'transaction date')
    return `${date
      .toLocaleString(`en-ET`, { dateStyle: 'short', timeStyle: 'medium', timeZone: date.getTimeZone })
      .replace(',', '')}`;
  if (from === 'ticket datetime')
    return `${date
      .toLocaleString(`en-ET`, { dateStyle: 'short', timeStyle: 'medium', timeZone: date.getTimeZone })
      .replace(',', '')}`;

  if (from === 'utcToClientStartDateTime') {
    const localDate = new Date(date.getTime() + date.getTimezoneOffset() * 60 * 1000);
    localDate.setDate(localDate.getDate() - 1);
    return `${localDate.getFullYear()}-${(localDate.getMonth() + 1).toString().padStart(2, '0')}-${localDate.getDate().toString().padStart(2, '0')}T${localDate.toLocaleTimeString('en-ET', { timeZone: 'UTC', hour12: false, hour: '2-digit', minute: '2-digit', second: '2-digit' })}.000Z`;
  }

  if (from === 'utcToClientEndDateTime') {
    const localDate = new Date(date.getTime() + date.getTimezoneOffset() * 60 * 1000);
    return `${localDate.getFullYear()}-${(localDate.getMonth() + 1).toString().padStart(2, '0')}-${localDate.getDate().toString().padStart(2, '0')}T${localDate.toLocaleTimeString('en-ET', { timeZone: 'UTC', hour12: false, hour: '2-digit', minute: '2-digit', second: '2-digit' })}.000Z`;
  }

  return `${date
    .toLocaleString(`en-ET`, { dateStyle: 'short', timeStyle: 'medium', timeZone: date.getTimeZone })
    .replace(',', '')}`;
};

export function getTimeZone() {
  const offset = new Date().getTimezoneOffset();
  const o = Math.abs(offset);
  return `${(offset < 0 ? '+' : '-') + `00${Math.floor(o / 60)}`.slice(-2)}:${`00${o % 60}`.slice(-2)}`;
}

export const TransformMenuArray = (inputArray) => {
  const transformedArray = inputArray.map((obj) => {
    const actions = obj.action
      .split(',')
      .map((action) => ({ name: action }))
      .filter((actionname) => actionname !== '' && actionname !== null);
    return { ...obj, action: actions };
  });

  const sortedArray = transformedArray.sort((a, b) => a.id - b.id);
  return sortedArray;
};

export const filterRoutesByObjectName = (routes, objects) =>
  routes.filter((route) => {
    const matchingObject = objects.find((obj) => obj.name === route.title);
    return matchingObject !== undefined;
  });

export const getPermissionActions = (data, name) => {
  const filteredData = data.filter((obj) => obj.name === name);
  if (filteredData.length > 0) {
    return filteredData[0].action;
  }
  return null; // Return null if no match is found
};

export const getBaseImageFromUrl = async (imageUrl) => {
  try {
    const response = await fetch(imageUrl);
    const blob = await response.blob();
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = function (event) {
        const base64Image = event.target.result;
        resolve(base64Image);
      };
      reader.readAsDataURL(blob);
      reader.onerror = function (error) {
        reject(error);
      };
    });
  } catch (error) {
    console.error('Error fetching or converting the image:', error);
    throw error;
  }
};

export const convertToUTC = (date) => {
  const utcDate = new Date(date.getTime() - date.getTimezoneOffset() * 60000);
  return utcDate;
};

export const ToastError = (msg) =>
  toast.error(msg, {
    position: 'top-center',
    autoClose: 1000,
    hideProgressBar: true,
    closeOnClick: true,
    theme: 'colored',
  });

export const ToastSuccess = (msg) => {
  toast.success(msg, {
    position: 'top-center',
    autoClose: 1000,
    hideProgressBar: true,
    closeOnClick: true,
    theme: 'colored',
  });
};

// export const formatNumber = (number) => {
//   if (number >= 1e6) {
//     // Divide by 1 million and format it to display 1 decimal place followed by 'M'.
//     return `${(number / 1e6).toFixed(1)} M`;
//   }
//   // For numbers less than 1 million, just format them as regular numbers.
//   return number.toLocaleString('en-US', { maximumFractionDigits: 2 });
// };

export const amountFormatter = (amount) => {
  const tempAmount = Number(amount);

  // 1,000,000 - 999,999,999
  // letter: 'M',
  // limit: 1e9
  if (tempAmount >= 1e9) {
    return `${(tempAmount / 1e9).toFixed(1)} B ${showCurrency()}`;
  }

  // 1,000 - 999,999
  //  letter: 'K',
  //  limit: 1e6
  if (tempAmount >= 1e6) {
    return `${(tempAmount / 1e6).toFixed(1)} M ${showCurrency()}`;
  }

  const formattedValue = new Intl.NumberFormat('en-ET', {
    style: 'currency',
    currency: `${showCurrency()}`,
    // currencyDisplay: 'name',
    minimumFractionDigits: 2, // Customize this based on your needs
    maximumFractionDigits: 2,
  }).format(Math.abs(tempAmount));

  const finalFormattedValue =
    tempAmount < 0
      ? `-${formattedValue.replace(`${showCurrency()}`, '')} ${showCurrency()}`
      : `${formattedValue.replace(`${showCurrency()}`, '')} ${showCurrency()}`;
  return finalFormattedValue;
};

// Function to generate MD5 hash and encode in Base64
export const generateHash = (params) => {
  // Concatenate all request parameters as a string
  const paramString = Object.keys(params)
    .sort()
    .map((key) => {
      if (Array.isArray(params[key])) {
        return `${key}=${JSON.stringify(params[key])}`;
      }
      return `${key}=${params[key]}`;
    })
    .join('&')
    .replace('&&', '&');
  // Append the secret key to the parameter string
  const data = `${paramString}&secret_key=${config.secretKey}`;
  // Generate SHA256 hash
  const hash = crypto.SHA256(data).toString(crypto.enc.Base64);
  // const hash = crypto.createHash('sha256').update(data).digest('base64');

  return hash;
};

export function formatNumber(number) {
  const roundedNumber = Number(number).toFixed(2);
  return numeral(roundedNumber).format('0,0.00');
}

export const getSelectedOdd = (oddSelectedId, MarketSelectedId, fixture) => {
  const oddData = Object.prototype.hasOwnProperty.call(fixture, 'Market')
    ? fixture.Market?.Odds.find((item) => item.id === oddSelectedId)
    : fixture.Markets?.find((market) => market.id === MarketSelectedId).Odds.find((item) => item.id === oddSelectedId);
  return oddData?.price?.toFixed(2);
};

export const roundto2 = (num) => Math.round(num * 100) / 100;

export function formatDateTimeUTC(timestamp, format) {
  return moment.utc(timestamp).format(format);
}

export const getIconName = (iconeName) => {
  switch (iconeName) {
    case 'sportsbook':
      return 'ion:football';
    case 'live':
      return 'fluent:live-24-regular';
    case 'slot':
      return 'mdi:slot-machine';
    case 'aviator':
      return 'mynaui:aeroplane';
    case 'virtuals':
      return 'fluent:games-24-regular';
    case 'top-leagues':
      return 'solar:star-shine-linear';
    default:
      return 'ion:football';
  }
};

export const RouteMapping = (user_routes, user_type) => {
  console.log(user_routes, user_type);

  const getDashboardByType = (userType) => {
    switch (userType) {
      case 'operator':
        return <OperatorDashboardAppPage />;
      case 'agent':
        return <AgentDashboardAppPage />;
      case 'cashier':
        return <CashierDashboardAppPage />;
      case 'aviator':
      case 'aviator-operator':
        return <AviatorAdminDashboardAppPage userType={user_type} />;
      // case :
      //   return <AviatorAdminDashboardAppPage />;
      default:
        return null;
    }
  };

  const paths = [
    { path: 'dashboard', element: getDashboardByType(user_type) },
    { path: 'createPlayer', element: <CreatePlayer /> },
    { path: 'balanceTransfer', element: <BalanceTransfer /> },
    { path: 'betTicketCode', element: <BetTicketCode /> },
    { path: 'betTicketCode/:pbet_uid', element: <BetTicketCode /> },
    { path: 'PlaceBetTicketCode', element: <PlaceBetTicketCode /> },
    { path: 'payout', element: <Payout /> },
    { path: 'payout/:betslip', element: <Payout /> },
    { path: 'transaction', element: <Transaction /> },
    { path: 'shopBalanceTransfer', element: <ShopBalanceTransfer /> },
    { path: 'betSlipCheck', element: <BetSlipCheck /> },
    { path: 'profile', element: <Profile userType={user_type} /> },
    { path: 'bettingReport', element: <BettingReport /> },
    { path: 'cashiersummaryreport', element: <PrintSummaryReport /> },
    { path: 'shop', element: <ShopPage model={'shop'} /> },
    { path: 'balanceControlCenter', element: <BalanceControlCenter /> },
    { path: 'fundTransferRequest', element: <FundTransferRequest /> },
    { path: 'creditRequest', element: <CreditRequest /> },
    { path: 'creditTransactions', element: <CreditTransactions /> },
    { path: 'playerrtp', element: <PlayerRTP /> },
    { path: 'relatedCashiers', element: <MyCashiers /> },
    { path: 'player', element: <Player userType={user_type} /> },
    { path: 'player-bets', element: <PlayerBet userType={user_type} /> },
    { path: 'operators', element: <Operator action={'operator'} /> },
    { path: 'operators/edit/:id', element: <OperatorForm action={'Edit'} /> },
    { path: 'operators-rtp', element: <OperatorRTP /> },
  ];

  let routes = [];
  user_routes.forEach((route) => {
    const items = paths.filter((item) => item.path.includes(route.slug));
    routes = routes.concat(items);
  });
  return routes;
  // const matchedPath = paths.find(path => path.path.includes(slug));

  // return matchedPath || null;
};

export const showCurrency = () => MY_GLOBAL_CONST.currency;

export const commonAgentLogic = (user) => {
  // Common Logic
  const allNull =
    user.shop !== null && user.shop.length > 0 ? user.shop.every((shop) => shop.operator_id === null) : false;
  const allSame =
    user.shop !== null && user.shop.length > 0
      ? user.shop.every(
        (shop, index, arr) => shop.operator_id === arr[0].operator_id && shop.role_name === arr[0].role_name
      )
      : false;
  return [allNull, allSame];
};

export const getTimeStamp = () => moment().unix();

export const getNestedValue = (obj, path) => {
  return path.split('.').reduce((o, p) => o && o[p], obj);
};
