import { Icon } from '@iconify/react';
import ArrowDownwardOutlinedIcon from '@mui/icons-material/ArrowDownwardOutlined';
import ArrowUpwardOutlinedIcon from '@mui/icons-material/ArrowUpwardOutlined';
import {
  Box,
  Button,
  Card,
  CardContent,
  Container,
  FormControl,
  InputLabel,
  MenuItem,
  Paper,
  Popover,
  Select,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Typography,
} from '@mui/material';
import IconButton from '@mui/material/IconButton';
import { Link } from 'react-router-dom';
import { useEffect, useMemo, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useForm } from 'react-hook-form';
import { aviatorPostData } from '../../../utils/api';
import { MY_GLOBAL_CONST } from '../../../utils/GlobalConstant';
import { formatDate, formatNumber, getNestedValue, showCurrency } from '../../../utils/helper';
import { fNumber } from '../../../utils/formatNumber';
import Label from '../../../components/label';
import Iconify from '../../../components/iconify';

const Operator = ({ model }) => {
  const [loading, setLoading] = useState(false);

  const [list, setList] = useState([]);
  const [extra_data, setExtraData] = useState(null);

  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const [search, setSearch] = useState('');

  const [status, setStatus] = useState('');
  const [isLive, setIsLive] = useState('');

  const [sortData, setSortData] = useState('');
  const [sortColumn, setSortColumn] = useState('');

  const [currentId, setCurrentId] = useState(null);
  const [open, setOpen] = useState(null);

  const Status = [
    { id: 'Active', label: 'Active', color: 'success' },
    { id: 'Inactive', label: 'Inactive', color: 'error' },
  ];

  const Live = [
    { id: true, label: 'Yes', color: 'info' },
    { id: false, label: 'No', color: 'warning' },
  ];

  const columns = useMemo(
    () => [
      { name: 'Name', columnName: 'name', align: 'center', isOrder: false },
      { name: 'Username', columnName: 'username', align: 'center', isOrder: false },
      { name: 'Email', columnName: 'email', align: 'center', isOrder: false },
      { name: 'GGR (%)', columnName: 'ggrPercentage', align: 'center', isOrder: false, isPercentage: true },
      { name: 'House Pot', columnName: 'housePot', align: 'center', isOrder: false, isNumber: true },
      { name: 'Jackpot', columnName: 'jackpot', align: 'center', isOrder: false, isNumber: true },
      { name: 'House Pot Balance', columnName: 'houseBalancePer', align: 'center', isOrder: false, isNumber: true },
      { name: 'Jackpot Balance', columnName: 'jackpotBalancePer', align: 'center', isOrder: false, isNumber: true },
      {
        name: 'Return To Player Pot',
        columnName: 'returnToPlayerPot',
        align: 'center',
        isOrder: false,
        isNumber: true,
      },
      { name: 'Language Code', columnName: 'languageCode', align: 'center', isOrder: false },
      { name: 'Currency Code', columnName: 'currencyCode', align: 'center', isOrder: false },
      { name: 'Status', columnName: 'status', align: 'center', isOrder: false, isStatus: true, stateName: Status },
      { name: 'Is Live', columnName: 'isLive', align: 'center', isOrder: false, isStatus: true, stateName: Live },
      { name: 'Created At', columnName: 'createdAt', align: 'center', isOrder: false, isDate: true },
      { name: '', isAction: true },
    ],
    []
  );

  const { handleSubmit, register } = useForm({
    defaultValues: {
      status: '',
      isLive: '',
    },
  });

  const clearFilter = () => {
    setSearch('');
    setStatus('');
    setIsLive('');
  };

  const onchangeStatus = (e) => {
    setStatus(e.target.value);
  };

  const onchangeIsLive = (e) => {
    setIsLive(e.target.value);
  };
  const handleFilterByName = (e) => {
    setPage(1);
    setSearch(e.target.value);
  };

  const onSearch = async () => {
    setLoading(true);
    try {
      const body = {
        data: {
          page,
          limit: rowsPerPage,
          search,
          isLive,
          status,
        },
      };
      const response = await aviatorPostData('/admin/admin-user', body);
      setList(response.data.list);
      setExtraData(response.data);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    onSearch();
  }, [page, rowsPerPage]);

  const handleSort = (column) => {
    if (sortColumn === column) {
      setSortData(sortData === 'ASC' ? 'DESC' : 'ASC');
    } else {
      setSortColumn(column);
      setSortData('ASC');
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage + 1);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(1);
  };

  const handleOpenMenu = (id, event) => {
    setOpen(event.currentTarget);
    setCurrentId(id);
  };

  const handleCloseMenu = () => {
    setOpen(null);
    setCurrentId(null);
  };

  return (
    <>
      <Container maxWidth="xl" className="cardDesign">
        <Helmet>
          <title>Operators | {`${MY_GLOBAL_CONST.main_title}`} Admin </title>
        </Helmet>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Box display="flex">
            <Icon icon="ri:admin-line" style={{ fontSize: '23px', marginRight: '3px', marginTop: '6px' }} />
            <Typography variant="h4" gutterBottom>
              Operators
            </Typography>
          </Box>
        </Stack>

        <form onSubmit={handleSubmit(onSearch)}>
          <Card
            sx={{
              minWidth: 275,
              height: 'auto',
              marginTop: '45px',
            }}
          >
            <CardContent>
              <Stack display="flex" flexDirection="row" className="temptranstaionStack pd0_mrg0">
                <Box padding={3} sx={{ flexBasis: '19%' }} className="slectInput width_100 pd0_mrg0">
                  <TextField value={search} type="text" onChange={handleFilterByName} placeholder="Search..." />
                </Box>

                <Box padding={3} sx={{ flexBasis: '25%' }} className="slectInput width_100 pd0_mrg0">
                  <FormControl sx={{ flexBasis: '25%' }} fullWidth>
                    <InputLabel id="demo-simple-select-label">Status</InputLabel>
                    <Select name="status" {...register('status')} id="status" value={status} onChange={onchangeStatus}>
                      <MenuItem key="" value={null}>
                        Select Status
                      </MenuItem>
                      {Status.map((option) => (
                        <MenuItem key={option.id} value={option.id}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Box>

                <Box padding={3} sx={{ flexBasis: '25%' }} className="slectInput width_100 pd0_mrg0">
                  <FormControl sx={{ minWidth: 150 }} fullWidth>
                    <InputLabel id="demo-simple-select-label">Is Live</InputLabel>
                    <Select name="isLive" {...register('isLive')} id="isLive" value={isLive} onChange={onchangeIsLive}>
                      <MenuItem key="" value={null}>
                        Select
                      </MenuItem>
                      {Live.map((option) => (
                        <MenuItem key={option.id} value={option.id}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Box>

                <Box margin={3} className="slectInput width_100 pd0_mrg0">
                  <Button
                    onClick={clearFilter}
                    color="secondary"
                    variant="outlined"
                    size="large"
                    disableElevation
                    disabled={loading}
                  >
                    Clear
                  </Button>
                </Box>
                <Box margin={3} className="slectInput width_100 pd0_mrg0">
                  <Button
                    type="submit"
                    color="secondary"
                    variant="contained"
                    size="large"
                    disableElevation
                    disabled={loading}
                  >
                    Search
                  </Button>
                </Box>
              </Stack>

              <Box mt={3} sx={{ overflow: 'auto' }}>
                <TableContainer component={Paper} sx={{ height: 540, scrollbarWidth: 'thin' }}>
                  <Table sx={{ minWidth: '1800px' }} stickyHeader aria-label="simple table" margin={1}>
                    <TableHead>
                      <TableRow>
                        {columns.map(
                          (column, index) =>
                            !column.isRowSkip && (
                              <TableCell
                                key={index}
                                align={column.align}
                                sx={{ width: '250px', padding: '10px' }}
                                onClick={() => (column.isOrder ? handleSort(column.columnName) : '')}
                              >
                                {column.name}
                                {column.isOrder ? (
                                  <IconButton aria-label="toggle password visibility">
                                    {sortData === 'ASC' ? (
                                      <ArrowUpwardOutlinedIcon sx={{ fontSize: '16px !important' }} />
                                    ) : (
                                      <ArrowDownwardOutlinedIcon sx={{ fontSize: '16px !important' }} />
                                    )}
                                  </IconButton>
                                ) : (
                                  ''
                                )}
                              </TableCell>
                            )
                        )}
                      </TableRow>
                    </TableHead>

                    <TableBody>
                      {list.map((row) => (
                        <TableRow key={row._id} padding={0}>
                          {columns.map((column) =>
                            !column.isRowSkip && !column.isAction ? (
                              <TableCell align={column.align} sx={{ width: '250px' }}>
                                {column.isStatus ? (
                                  <Label
                                    color={
                                      column.stateName.find((s) => s.label === getNestedValue(row, column.columnName))
                                        ?.color
                                    }
                                  >
                                    {getNestedValue(row, column.columnName)}
                                  </Label>
                                ) : column.isDate ? (
                                  formatDate(row?.[column.columnName], 'ticket datetime')
                                ) : column.isCount ? (
                                  fNumber(row[column.columnName])
                                ) : column.isAmount ? (
                                  `${fNumber(row[column.columnName])} ${showCurrency()}`
                                ) : column.isNumber ? (
                                  `${fNumber(row[column.columnName])}`
                                ) : column.isPercentage ? (
                                  `${formatNumber(row[column.columnName])} %`
                                ) : (
                                  getNestedValue(row, column.columnName) || '-'
                                )}
                              </TableCell>
                            ) : (
                              <TableCell align="right">
                                <IconButton
                                  size="large"
                                  color="inherit"
                                  onClick={(e) => {
                                    handleOpenMenu(row._id, e);
                                  }}
                                >
                                  <Iconify icon={'eva:more-vertical-fill'} />
                                </IconButton>
                              </TableCell>
                            )
                          )}
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                  {!loading && list.length <= 0 && (
                    <TableCell align="center" colSpan={8}>
                      <Typography variant="h6" textTransform={'capitalize'}>
                        {' '}
                        data not found !
                      </Typography>
                    </TableCell>
                  )}
                </TableContainer>
                {!loading && list.length > 0 && (
                  <TablePagination
                    rowsPerPageOptions={[10, 25, 50]}
                    component="div"
                    count={extra_data ? extra_data.pagination.totalCounts : 0}
                    rowsPerPage={rowsPerPage}
                    page={page - 1}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                  />
                )}
              </Box>
            </CardContent>
          </Card>
        </form>
      </Container>
      <Popover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleCloseMenu}
        anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        PaperProps={{
          sx: {
            p: 1,
            width: 140,
            '& .MuiMenuItem-root': {
              px: 1,
              typography: 'body2',
              borderRadius: 0.75,
            },
          },
        }}
      >
        <MenuItem component={Link} to={`/aviator/operators/edit/${currentId}`}>
          <Iconify icon={'eva:edit-fill'} sx={{ mr: 2 }} />
          Edit
        </MenuItem>

        {/* <MenuItem sx={{ color: 'error.main' }} onClick={handleDeleteClickOpen}>
          <Iconify icon={'eva:trash-2-outline'} sx={{ mr: 2 }} />
          Delete
        </MenuItem> */}
      </Popover>
    </>
  );
};

export default Operator;
